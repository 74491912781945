.Reasion {
  padding: 0 2rem;
  display: flex;
  gap: 2rem;
}

.left-rea {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  grid-auto-rows: 1fr;
}

.left-rea > img {
  object-fit: cover;
}

.left-rea > :nth-child(1) {
  width: 12rem;
  grid-row: 1/3;
  height: 28rem;
}
.left-rea > :nth-child(2) {
  width: auto;
  height: 16rem;
  grid-column: 2/4;
}
.left-rea > :nth-child(3) {
  width: 14rem;
  grid-column: 2/3;
  grid-row: 2;
}
.left-rea > :nth-child(4) {
  width: 10rem;
  grid-column: 3/4;
  grid-row: 2;
  height: 11.2rem;
}

.right-rea {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-transform: uppercase;
}

.right-rea > :nth-child(1) {
  font-weight: bold;
  color: #f48915;
}

.reasion-tital {
  font-size: 3rem;
  font-weight: bold;
  color: white;
}

.reasion-detail {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.reasion-detail > div {
  display: flex;
  gap: 1rem;
  color: white;
  font-weight: bold;
}

.reasion-detail > div > img {
  width: 2rem;
  height: 2rem;
}

.right-rea > :nth-child(4) {
  text-transform: uppercase;
  color: #9c9c9c;
}

.partner_img {
  display: flex;
  gap: 0.7rem;
}

.partner_img > img {
  width: 2.5rem;
}

@media screen and (max-width: 768px) {
  .Reasion {
    flex-direction: column;
  }
  .left-rea {
    grid-auto-rows: auto;
    overflow: hidden;
  }
  .left-rea > :nth-child(1) {
    width: 7rem;
    height: 17rem;
  }
  .left-rea > :nth-child(2) {
    width: 11rem;
    height: 10rem;
  }
  .left-rea > :nth-child(3) {
    width: 7rem;
  }
  .left-rea > :nth-child(4) {
    width: 7rem;
    height: 6rem;
  }
}
